/* Medium screen */
@media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* Big screens (desktops, etc.) */
  @media (min-width: 1025px) {
    .footer_row{
        margin-left: 100px !important;
         margin-right: 100px !important;
    }
    #collapseExample1 {
        position: absolute !important;
        right: 11% !important;
        z-index: 4 !important;
    }
    iframe{

        /* width: 740px !important; */
        border: none;
        height: 50px;
        min-height: 600px !important;
        display: block;
        z-index: 999999999;
    }
    .order_summary_title {
        font-size: 17px !important;
        text-align: center;
        padding: 0px !important;
    }
    .S9gUrf-YoZ4jf{
        height: 44px !important;
      }
  }