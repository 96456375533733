/* src/styles/fonts.css */
@font-face {
    font-family: 'Candara';
    src: url('../../public/fonts/CANDARA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Candara', sans-serif;
}
