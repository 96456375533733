
/* Ensure body fits within screen size */

body {
  max-width: 100vw; /* Prevent horizontal overflow */
  height: auto;
  overflow: visible; /* Ensure body can scroll */
  font-family: 'Candara' !important;
}


#root{
  font-family: 'Candara' !important;
  font-size: 18px;
}
@keyframes shake-animation {
  0% { transform:translate(0,0) }
 1.78571% { transform:translate(5px,0) }
 3.57143% { transform:translate(0,0) }
 5.35714% { transform:translate(5px,0) }
 7.14286% { transform:translate(0,0) }
 8.92857% { transform:translate(5px,0) }
 10.71429% { transform:translate(0,0) }
 100% { transform:translate(0,0) }
}
.error-vibrate{
  animation: 4.72s ease 0s normal none running shake-animation;
  transform-origin: 50% 50%;
}
.best-seller{
  font-size: 22px;
}

#product-div{
  text-align: center !important;
  padding:0px 51px 47px 52px !important;
}
.searchbar{
 margin: 24px !important;
  width: 80% !important;
}
.error-border {
  border: 1px solid red !important; /* !important will override any inline or specific styles */
}
.header{
  background: #dadada;
}
.logo_div{
display: grid;
align-items: center;
justify-content: center; 
}
.design_div{
  padding: 12px 28px 12px 28px !important;
  color: black;
}
.filter_ul {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  display: flex;
  list-style-type: none;
  padding: 0;
}
.filter_ul li{
  color: black;
}
.design_ds{
  font-size: 15px !important;
  margin-top: 3px;
}
.design_image{
  width: 100%;
}
.design_name{
  font-size: 17px;
  color: black;
  padding-top: 10px;
  margin-bottom: 0px;
}
.price_s{
  margin-top: -12px;
  font-weight: 700;
  font-size: 17px;
}
.sku_design{
  position: absolute;
  color: black;
  font-size: 11px;
  top: 16px;
  right: 33px;
}
.filter_ul{
  margin: 15px 10px 10px 0px;
}
.logo_imge{
  width: 40%;
  margin: 3%;
}
.banner_imgage{
  width: 100%;
}
.explore_div{
  text-align: center;
}
.product_image{
  width: 100%;
}
.text-p1{
  margin-bottom: -16px;
    text-align: center;
    line-height: 30px;
    margin-top: 30px;
}

/* src/SlickSlider.css */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.select-size-error{
  font-size: 12px;
  margin-top: -18px;
  margin-bottom: 8px;
  color: red;
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}
.text-div-main{
  justify-content: center;
}
.text-p{
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
}

.explore_title{
     margin-top: 12px;
     text-align: center;
     font-weight: 400;
     font-size: 28px;
   
  padding: 14px;
}

.product_name{
  font-weight: 600;
  color: black;
    font-size: 17px;
}
.text_for_product{
  margin-top: -5px;
  color: black;
    font-size: 14px;
}
.add_image{
  width: 100%;
}
.footer_for{
  /* list-style: none; */
  line-height: 30px;

  list-style-type: none !important;
    padding: 0;
    /* list-style: none; */
}
.footer_container{
  background: #4c4452;
    color: white;
}
.footer_row{
  font-size: 15px;
  padding: 30px;
  display: flex !important;
 
  flex-direction: row !important;
  justify-content: space-around;
}
.profile_div{
  text-align: right;
  padding: 20px 42px 19px 10px;
 
}
.cart_img{
  cursor: pointer;
  width: 10%;
  padding: 10px;
}
.profile_img{
  width: 10%;
  padding: 10px;
}

.ul_div{
  text-align: center;
}

/* Add these styles to your CSS file */
/* .header {
  padding: 20px;
  background-color: #f8f9fa;
} */

.logo_image {
  width: 200px;
}

.note-error-uploadfile{
  font-size: 14px;
  color: red;
  margin-bottom: 0px;
  margin-top: 7px;
}

.slick-prev, .slick-next {
   top: 41% !important;
}
.cart_img, .profile_img {
  
  width: 50px;
  cursor: pointer;
}

.searchbar {
  width: 100%;
}
.topbar{
  justify-content: center;
  background: #b3b3b3;
}
#collapseExample1{
  position: absolute;
}
#collapseExample2{
  position: absolute;
  right: 16px;
  z-index: 2;
}
.addtocart{
  opacity: 0;
}
.checkout_button_div{
  display: grid;
  place-items: center;
}
/* Main.css */
.topbar {
  position: relative; /* Ensure relative positioning for absolute children */
  cursor: pointer; /* Add cursor pointer for hover effect */
}

.collapsible-container {
  position: absolute;
  top: 100%; /* Position below the topbar */
  left: 0;
  z-index: 1;
  width: 100%; /* Full width of the topbar */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow: hidden;
  max-height: 0; /* Initially collapsed */
  transition: max-height 0.3s ease-out; /* Smooth transition effect */
}
.error-checkout{
  color: red;
  font-size: 14px;
}

.collapsible-container.collapsed {
  max-height: 800px; /* Adjust the max height as needed */
}

.carousel {
  width: 400px !important; 
  position: relative;
}
.content {
  padding: 10px;
}
.ul_sub_div{
  text-align: left;
  list-style: none;
  line-height: 39px;
}
.personalise_title{
  /* padding-left: 45px; */
  font-size: 19px;
  font-weight: 500;
  margin: 8px;
  color: white;
  text-align: center;
}
.ul_footer_div{
  list-style: none;
  display: flex;
}


.continue_shopping1 {

  background: #f4d00d;
  border: none;
  padding: 6px;
  
  font-size: 10px;
}
.continue_shopping_div{
  text-align: right !important;
}.cart_div{
  text-align: left !important;
}
.cart-container{
  font-size: 14px;
}
.cart-container2{
  font-size: 14px;
}
.cart_div1{
  text-align: left;
}
.cart_div2{
  text-align: right;
}
.div3{
  text-align: left;
}
.div4{
  text-align: right;
}
#collapseExample1 {
  position: absolute;
  z-index: 4 !important;
}
.social_img{
 
  margin: 6px;
  width: 14%;
}
.social_img2{
  width: 10%;
  margin: 4px;
}
.footer_div1{
  display: flex;
}
.first_footer_div{
  display: flex;
}
.connect_wu{
  text-align: center;
  margin: 6px;
}

.search-list-ul{
  position: absolute;
    background: gray;
    list-style: none;
    width: 75%;
    z-index: 8;
    color: white;
    left: 8%;
    top: 70%;
}


.search-list-ul-li{
  color: white;
  padding: 7px;
}
.footer_for{
  color: white;
}
.footer1{
  cursor: pointer;
  color: white;
}
.footer-title{
  font-weight: bold;
}
.contact_banner{
  width: 100%;
}
.sub_contact_banner{
  display: grid;
  justify-content: center;
  align-items: center;
}
.card_details{
  margin: 20px;
  padding: 76px;
}
.top_ul{
  color: black;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.top_ul:hover {
  transform: scale(1.2);  
  /* background-color: gray;  */
}

.sign_in{
  font-weight: bold;
}
.sign_in_div{
  font-size: 15px;
  padding: 4% !important;
  border-right: 2px solid #d4d4d4;
}
.sign_up_div{
  padding: 4% !important;
}
.details_div{
  padding: 53px !important;
}
.shipping-checkbox{
  margin-right: 10px;
  margin-bottom: 20px;
}
.new-address-checkbox{
  margin-right: 10px;
  margin-bottom: 20px;
}
.checkout_button_div{
  background: #c7c7c7;
}
.checkout_row{
  margin: 2%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.collapse {
  display: none;
  transition: height 0.3s ease-out; /* Optional: Add animation */
}

.collapse.show {
  display: block;
}
.split{
  border: 0;
  height: 3px;
  background: linear-gradient(to right, #47403d 50%, #f4d00d 50%);
  margin: 0px 0;
}
.text-div{
  font-size: 17px;
}
.subscribe_row{
  justify-content: center;
}

.subscirbe_row2{
  padding: 13px;
justify-content: center;
}


.suscribe-top{
  margin-top: 18px;
}
.subscribe-top{
  margin-top: 20px;
}
.center2{
  text-align: center;
}
.free-access{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.subscribe_button{
  background: #f8d500;
  border: none;
  /* margin-top: 26px; */
  padding: 8px;
  border-radius: 9%;
}
.split1{
  border: 0;
  height: 3px;
  background: linear-gradient(to right, #47403d 50%, #f4d00d 50%);
  margin-bottom: 20px;
}

.split2{
  border: 0;
  height: 3px;
  background: linear-gradient(to right, #f4d00d 50%,  #47403d 50%);
  margin-top: 20px;

}
.email_div{
  margin-right: 20px;
  margin-left: 20px;
  text-align: right;
  display: grid;
  align-items: center;
}
.text-div {
  width: 43% !important;
  font-size: 17px;
  margin-right: 20px;
  margin-left: 20px;
}
.button_div{
  margin-right: 20px;
  margin-left: 20px;
}
.heading{
  font-size: 20px;
    font-weight: 600;
}
.circle {
  /* width: 150px;
  height: 150px; */
  border: 2px solid #f4d00d;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-image {
  width: 100%;
  height: auto;
}
.slick_div{
  padding: 20px;
}


.color-buttons {
  margin-top: 12px;
}
.select-color-label{
  font-weight: bold;
  font-size: 17px;
}

.color-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Ensures the button is round */
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.black {
  background-color: black;
  border: 1px solid;
}

.white {
  background-color: white;
  border: 1px solid;
  color: black; /* Ensures text is visible on white background */
}

.color-button:focus {
  outline: none; /* Remove default focus outline */
}

.color-button:hover {
  opacity: 0.8; /* Lower opacity on hover for visual feedback */
}

.seller_image{
  width: 100%;
}
.saller-box{
  padding: 20px;
  box-shadow: -4px -1px 8px rgba(0.3, 0.3, 0.3, 0.3);
}
.seller-row{
  /* padding: 23px; */
  padding: 10px 10px 2px 10px;
text-align: center;
}
.seller-product-row{
  padding: 9px 10px 10px 10px;
}
.icon_ad{
  width: 12%;
}
.icon_ad1{
  cursor: pointer;
  width: 12%;
}
.icon-div{
  text-align: center;
}
.icon-row{
  justify-content: space-around;
}
.button-row-modal{
  justify-content: space-around;
}
.title-icon{
  cursor: pointer;
  padding: 10px;
}
.shop-now-1{
  position: absolute;
  left: 27%;
  top: 66%;
  border: none;
  padding: 6px;
  background: #f4d00d;
  border-radius: 5px;

}
.shop-now-2{
  position: absolute;
  right: 19%;
  bottom: 7%;
  border: none;
  padding: 6px;
  background: #f4d00d;
  border-radius: 5px;

}
.slider-pname{
  color: black;
  margin-top: 14px;
  /* padding: 7px; */
  text-align: center;
}
.cart-data-row{
  line-height: 0px;
}
.remove_cart{
  cursor: pointer;
}
.checkout-button{
  padding: 8px;
    background: #f4d00d;
    border: none;
    border-radius: 8px;
}
.preview_div2{
  display: none;
}
.p_cart_name{
  font-size: 17px;
}
.p_cart_rate{
  font-size: 16px;
}
.your_Cart_div{
  margin-top: 10px;
}
.icon-p{
  display: none !important;
  
}
.product_cart{
  line-height: 17px;
}

.white-button{
  padding: 12px;
  width: 20px;
  margin: 10px 4px 15px 0px;
  height: 20px;
  background: black;
  border: 2px solid black;
  border-radius: 50%;
}
.black-button{
  padding: 12px;
  width: 20px;
  margin: 10px 4px 15px 0px;
  height: 20px;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
}
.color-div{
  margin-top: 10px;
  margin-bottom: -40px;
  display: flex;
  flex-wrap: nowrap;
}
.upload_file{
  border: 1px solid !important;
  width: 46% !important;
  height: 32px !important;

}
.upload-label{
  font-weight: bold;
  font-size: 17px;

}
.size-t{
  border: 1px solid !important;
  width: 46% !important;
  height: 32px !important;
}
.view-chart{
  cursor: pointer;
  color: blue;
  margin-top: 15px;
}
.note-error-t-size{
  margin-bottom: 0px;
    font-size: 12px;
    color: red;
    margin-top: 10px
}
#button1{
background: white;
}
#button2{
  background: black;
}
.editor-canvas-row{
  margin-top: 45px;
}
.toggle-div{
  cursor: pointer;
}
.collapse-title{
  background: #efefef;
  padding: 8px;
}
.collapse-profile-div{
  margin-top: -16px;
  margin-bottom: 20px;
}
.checkout-div{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.admin-card{
      padding: 28px;
    width: 56%;
}
.admin-container{
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  margin-bottom: 5px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #000;
  display: block;
}

.sidebar ul li a:hover,
.sidebar ul li.active a {
  background-color: #007bff; /* Change to your desired hover background color */
  color: #fff;
  padding-left: 10px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s, padding-left 0.3s;
}
.discount-pirce{
  text-decoration: line-through;
  color: gray;
  font-size: 15px;
}
.youtube-social-img
{
  width: 28px !important;
}


.review-ds1{
  margin-bottom: 4px;
  font-size: 15px;
}
.review-user{
  font-weight: 900;
    font-size: 14px;
    text-align: right;
}
.review-star{
  margin-bottom: 4px;
}
.review-title{
  font-size: 20px;
  font-weight: 500;
}
.offer-design{
  float: right;
  font-size: 16px;
  padding-left: 7px;
  font-weight: normal;
  /* background: yellow; */
}
.offer-image{
  width: 58px;
  float: right;
}
.message-error{
  font-size: 15px;
  color: red;
}
.select-design-size{
 
  border: 1px solid black;
    width: 62% !important;
    font-size: 16px;
    height: 32px !important;
    margin-bottom: 23px;
}
.label-size{
  font-weight: 600;
}
.note-error-for-color{
  font-size: 12px;
  margin-bottom: 0px;
  color: red;
  margin-top: 8px;
}
/* Main.css */

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust the height to center vertically */
}

.thank-you-card {
  background-color: #f8f9fa; /* Light background color for the card */
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for the card */
  text-align: center;
  max-width: 400px;
  margin: auto;
}

.thank-you-card p {
  font-size: 18px;
  color: #333; /* Darker text color */
  margin: 10px 0;
}
.Add-text-qr-code{
  font-weight: 600;
  font-size: 16px;
}
.qr-code-text{
  border: 1px solid black !important;
  width: 50% !important;
}
.preview-text{
  text-align: center;
  position: absolute;
  bottom: 22%;
  right: 38%;
}
.table td, .table th {
  padding: 5px;
  border-top: 1px solid #dee2e6;
}
.table-size-chart{
  /* min-width: 765px !important; */
}
.note-error{
  color: red;
    font-size: 12px;
    margin-top: -22px;
    margin-bottom: 7px;
}
.quantity_input_change{
  width: 62%;
}
.row-for-order{
  text-align: center;
}

.profile-label{
  text-align: center;
  text-decoration: underline;
  margin-bottom: 5px;
  font-size: 15px;
  color: black;
  cursor: pointer;
}
.button-profile-div{
  text-align: center;;
}
.profile-btn-update{
  background: #f8d500;
  border: none;
  margin-top: 26px;
  padding: 4px;

}
.account-created-note{
  color: green;
}
.term-priv{
  color: black !important;
  font-weight: bold;
  text-decoration: underline;
}
.card-data-desc{
  margin-bottom: 2px;
}
.update-profile-pic{
  width: 75% !important;
  margin-bottom: 20px !important;
}
.profilfe-img{
    width: 84%;
}
.qr-note{
  font-size: 14px;
  color: red;
  margin-bottom: 0px;
  margin-top: 10px;

}
.profile-div{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.postcode-btn{
 
    background: #f4d00d !important;
    border: none !important;
    padding: 5px !important;
    color: black !important;
    font-size: 15px !important;
    margin-top: 10px !important;

}
.submit-check-btn-div{
  text-align: center;
}
.f-text1{
  background: #f4d00d;
  padding: 6px;
  text-align: center;
}
.continue_google{
  white-space: nowrap;
  background: #f4d00d;
  border: none;
  margin-top: 14px;
  padding: 7px;
  font-size: 15px;
  color: black;
}

.f-text2{
  padding: 6px;
  text-align: center;
}
.avalibale-location{
  margin-top: 10px;
  color: green;
}
.delivery-data{
  margin-bottom: 4px !important;
}
.delivery-data-output{
  text-align: center;
}
.empty-cart-title{
  font-size: 14px;
  text-align: center;
}
.about-us-title{
  margin-top: 10px;
}
.introduction-us-title{
  margin-top: 10px;
}
.terms-condition-title{
  margin-top: 10px;
  margin-left: -18px;
}
.use-promo{
  padding: 10px;
  color: white;
  background: #4c4452;
}
.discount-div{
  margin-top: 15px;
}
.collapsible-div{
  padding: 28px 10px 28px 10px;
    border: 1px solid black;
    margin-top: -17px;
    text-align: center;
}
.apply-btn{
  white-space: nowrap;
  background: #f4d00d;
  border: none;
  padding: 5px;
  font-size: 15px;
}
.coupon-input{
  width: 68%;
  padding: 4px;
}
.vedio-div {
  z-index: 9999;
  position: absolute;
  bottom: -354%;
}
.show-vedio-title{
  cursor: pointer;
    color: blue;
    text-decoration: underline;
}
.modal-content{
  padding: 0 !important;
}
.footer-vedio{
  width: 122px !important;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
}
/* .footer-vedio:hover{
  transform: scale(2.3);
  transition: 1s;
  position: fixed;
  left: 0%;
  margin: 10%;
} */

/* .modal-fullwidth {
  max-width: 100vw !important;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: 100vh !important;
}  */
.roc{
  color: #4c4552;
  margin-bottom: 1px;
  text-align: center;
  font-size: 13px;
  margin-top: 0px;
  font-weight: bold;
}
.rocp{
  margin-bottom: 0px;
}
#pp_loader_div{
  text-align: center ;
  padding: 10px;
}
.amazon-icon{
  width: 51px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px 20px 55px 20px;
}
.whatsapp-icon{
  width: 51px;
    position: fixed;
    bottom: 71px;
    right: 0;
    margin: 20px 20px 55px 20px;
}
.cart-image-modal{
  padding: 0% !important;
}




