
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  body {
    font-family: 'Candara' !important;
  }

  #root{
    font-family: 'Candara' !important;
    font-size: 12px;

  }
}
