.cart_button{
    margin: 43px;
    font-size: 13px;
    border: none;
    padding: 6px;
    background-color: #f8d500;
}
.iframe_editor{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.note2{
    text-align: center;
    color: red;
    font-size: 14px;
}
.editor_vareprint_logo{
    margin: 10px;
    width: 40%;
}
.editor_vareprint_logo_row{
justify-content: center;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 120px;
    height: 20px;
    -webkit-mask: radial-gradient(circle closest-side,#000 94%,#0000) left/20% 100%;
    background: linear-gradient(#000 0 0) left/0% 100% no-repeat #ddd;
    animation: l17 2s infinite steps(6);
  }
  @keyframes l17 {
      100% {background-size:120% 100%}
  }