.design-image{
    width: 100%;
}
.preview_dec{
  line-height: 29px;
}
.preview_design_name{
  font-size: 18px;
  font-weight: 600;
}
.personalisation_button{
    background: #f8d500;
      border: none;
      margin-top: 26px;
    padding: 4px;
}
.preview_price{
  font-size: 20px;
  font-weight: 600;
}
.preview-container{
  /* margin-bottom: 30px; */
}
.note_{
  color: red;
}
.note_for_shown{
  text-align: center;
  margin-top: 17px;
}
.preview-images{
 
  padding: 7px;
    width: 75%;
}
.preview_div{
  text-align: right;
}
.label_quantity{
  font-weight: 600;
}
