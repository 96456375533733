@import '~@fortawesome/fontawesome-free/css/all.min.css';
/* Mobile styles (up to 767px) */
@media only screen and (max-width: 767px) {
    body {
      font-family: 'Candara' !important;
    }
  
    #root{
      font-family: 'Candara' !important;
      font-size: 12px;
  
    }
   
    .header {
      
      display: flex !important;
      flex-wrap: nowrap !important;
    }
    .top1{
     
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
    
    }
    
    .logo_image {
      width: 94px;
  }
  .searchbar {
    font-size: 8px !important;
    margin: 0px 0px 0px 12px !important;
    width: 80% !important;
    height: calc(0.8rem + .75rem + 2px) !important; 
  }
  .profile_img {
    /* width: 10%; */
    padding: 16px !important;
  }
  .cart_img {
    /* width: 10%; */
    padding: 13px !important;
  }
  .banner_imgage {
    width: 103%;
  }
  .slick-initialized .slick-slide{
    outline: none;
    width: 120px !important;
  }
  .table-size-chart{
    min-width: 100% !important;
  }
  .note-error {
    color: red;
    font-size: 12px;
    margin-top: -14px;
    margin-bottom: 7px;
}
  .explore_title {
    margin-bottom: 47px;
    /* margin-top: 12px; */
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    padding: 14px 10px 0px 8px;
  
  }
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 31% !important;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .icon_ad1 {
    width: 21%;
  }
  .icon_ad {
    width: 21%;
  }
  .profile_div {
    text-align: right;
    padding: 0px 0px 0px 0px !important;
  }
  .personalise_title {
    font-size: 13px;
    font-weight: 500;
    margin: 8px;
    color: white;
    text-align: center;
  }
  
  .icon-row{
    margin-top: 17px;
    margin-bottom: -9px;
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .subscirbe_row2{
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .icon-div{
    text-align: center;
  }
  .free-access {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  
  .footer_row {
    padding: 30px;
    display: inline !important;
    justify-content: space-around;
    flex-direction: row;
  }
  .for-mobile{
    text-align: center;
  }
  .for-mobile1{
  display: none;
  }
  
  .foot-row{
    display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
  
  }
  .footer2-row{
   
      display: flex !important;
      flex-wrap: nowrap !important;
  
  }
  .payment-icon{
    text-align: center;
  }
  
  .social_img2 {
      width: 7%;
      margin: 4px;
  }
  
  /* .preview_div{
  display: grid !important;
  width: 133px !important;
  } */
  
  .preview-images {
    padding: 7px;
    width: 34% !important;
  }
  .preview-row {
    display: flow !important;
    flex-wrap: nowrap !important;
  }
  iframe{
    position: sticky;
    top: 0;
    width: 370px;
    border: none;
    height: 336px !important;
    display: block;
    z-index: 999999999;
  }
  .editor_vareprint_logo {
    margin: 10px;
    width: 100%  !important;
  }
  .cart-row2{
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .cart-row3{
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .cart-row4{
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .cart_image {
    width: 100% !important;
  }
  .shop-now-1 {
    position: absolute;
    left: 27%;
    font-size: 6px;
    top: 66%;
    border: none;
    padding: 2px;
    background: #f4d00d;
    border-radius: 5px;
  }
  
  .shop-now-2 {
      position: absolute;
      right: 19%;
      bottom: 6%;
      font-size: 6px;
      border: none;
      padding: 2px;
      background: #f4d00d;
      border-radius: 5px;
  }
  .preview_div{
    display: none;
  }
  .preview_div2{
    display: none;
  }
  .hover-row-top{
  display: flex;
  flex-wrap: nowrap !important;
  }
  .top_ul{
   
      /* margin-top: -11px !important; */
      margin-bottom: -11px !important;
      white-space: nowrap !important;
  

  }
  .top_nav_row{
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .ul_div{
    width: 120px !important;
  }
  
.footer_container {
  /* padding: 20px; */
  /* background-color: #f8f9fa; */
}




.for-mobile, .for-mobile1 {
  /* margin-bottom: 20px; */
}

.heading {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  display: inline-flex;
}

.footer_for {
  list-style-type: none;
  padding: 0;
  font-size: 14px !important;
}

.footer1 {
  margin: 5px 0;
}

.connect_wu {
  margin: 10px 0;
}


.payment-icon img {
  margin: 5px;
}
.product_image{
  margin-top: -62px;
  width: 100% !important;
}
/* .design_div {
  padding: 12px 28px 12px 28px !important;
  color: black;
  width: 216px !important;
} */

/* Styles for collapsible sections in mobile view */
@media (max-width: 767px) {
  .collapsible {
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 0;
  }

  .collapsible.expanded {
    max-height: 500px; /* Set this to the height of the content or a maximum height */
  }
}


/* Styles for non-collapsible sections in larger screens */
@media (min-width: 768px) {
  .collapsible {
    max-height: none;
  }

  .heading {
    cursor: default;
  }
}
.slick-next {
  right: -16px !important;
}
.slick-prev {
left: -15px !important;
}
.design_div{
  padding: 12px 28px 12px 28px !important;
  color: black;
  width: 180px !important;
}
.slick_div {
  width: 110% !important;
  padding: 20px;
}
.seller-product-row {
  display: flex;
  justify-content: center;
  text-align: center;
  /* flex-wrap: nowrap !important; */
}
.best-seller{

  font-size: 18px;
}
.seller_image{
  width: 80%;
  padding: 12px;
}
.subscirbe_row2{
  margin-top: -30px !important;
  justify-content: space-around;
}
.email_div{
  margin-right: 0px;
  margin-left: 0px;
  text-align: right;
  display: grid;
  align-items: center;
}

.text-div {
  width: 43% !important;
  font-size: 17px;
  margin-right: 0px;
  margin-left: 0px;
}
.button_div{
  margin-right: 0px;
  margin-left: 0px;
}
.text-p1 {
  font-size: 13px;
  margin-bottom: -22px;
  text-align: center;
  line-height: 30px;
  margin-top: 12px;
}
.text-p {
  font-size: 13px !important;
  padding: 1px 7px 4px 26px !important;
  text-align: center;
  line-height: 20px !important;
  margin-top: 30px;
}
.fa{
  font-size: 16px;
}
.icon-p{
  display: block !important;
  padding-right: 7px !important;
}
.price_s {
  margin-top: -12px;
  font-weight: 700;
  font-size: 14px !important;
}
.design_image {
  width: 120% !important;
}
.design_ds {
  font-size: 12px !important;
  margin-top: 3px;
}
.filter_ul {
  font-size: 12px !important;
  margin: 15px 4px 9px 12px !important;
}
.design_name {
  font-size: 14px !important;
  color: black;
  padding-top: 10px;
  margin-bottom: 0px;
}
.preview_dec {
  font-size: 14px !important;
  line-height: 23px;
}
.preview_design_name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}
.desc-card{
  padding: 7px;
}
.quantity_input_change{
  font-size: 14px;
}
.label_quantity {
  font-size: 14px !important;
  font-weight: 600;
}
.personalisation_button {
  background: #f8d500;
  border: none;
  margin-top: 26px;
  padding: 4px;
  font-size: 13px !;
}
.p_cart_name {
  white-space: nowrap !important;
  font-size: 15px !important;
}
.cart-quantity{
  margin-top: -10px;
}
.price_title_cart{
  margin-right: -47px;
}
.total_cart_div{
  
  display: flex;
  text-align: left !important;
  flex-wrap: nowrap !important;
}
.order_summary_title {
  font-size: 17px;
  text-align: center;
  padding: 14px;
  margin-bottom: -16px !important;
}
.rate-cart{
  text-align: center;
}
.login-container-row{
  padding: 20px;
}
.sign_in {
  font-size: 19px;
  font-weight: bold;
}
.signintitle{
  font-size: 14px;
}
.instruction-login{
  font-size: 14px;
}
.login-button-div{
  display: flex;
  flex-wrap: nowrap !important;
}
.continue_shopping {
  white-space: nowrap !important;
  background: #f4d00d;
  border: none;
  padding: 5px;
  font-size: 15px;
}
.sign_in_div {
  font-size: 14px !important;
  padding: 4% !important;
  border-right: none;
  border-bottom: 2px solid gray !important;
}
.white-button{
  padding: 8px !important;
  width: 20px;
  margin: 10px 4px 15px 0px;
  height: 20px;
  background: black;
  border: 2px solid black;
  border-radius: 50%;
}
.black-button{
  padding: 8px !important;
  width: 20px;
  margin: 10px 4px 15px 0px;
  height: 20px;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
}
.size-t{
  border: 1px solid !important;
  width: 46% !important;
  height: 31px !important;
}


.collapsible-container {
 display: none;
}
.discount-pirce {
  text-decoration: line-through;
  color: gray;
  font-size: 11px !important;
}
.offer-design {
  float: right;
  font-size: 11px !important;
}
.offer-image{

    width: 20px;
    width: 40px;
    float: right;

}
.footer-vedio{
  width: 65px !important;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
}
.roc{
  color: #4c4552;
  margin-bottom: 1px;
  text-align: center;
  font-size: 9px;
  margin-top: -9px;
  font-weight: bold;
}
.rocp{
  margin-bottom: 0px;
}
.amazon-icon {
  width: 33px !important;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px 20px 25px 20px !important;
}
.whatsapp-icon{
  width: 33px !important;
    position: fixed;
    bottom: 45px;
    right: 0;
    margin: 20px 20px 25px 20px !important;
}

}
