.admin_row{
    justify-content: center;
}
.admin_card{
    padding: 10%;
}

/* App.css */
body, html, #root {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  .App{
    height: 100%;
  }
  label{
    font-size: 15px;
  }
  .layout {
    display: flex;
    /* height: 100%; */
  }
  
  .sidebar {
    width: 200px;
    background-color: #333;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 15px 0;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar ul li a:hover {
    text-decoration: underline;
  }
  .cart-button-row{
    text-align: center;
  }
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .cart-button{
    background: #f4d00d;
    border: none;
    padding: 6px;
    font-size: 10px;
  }
  
  .navbar {
    background-color: #007bff;
    color: white;
    padding: 10px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .footer {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
  }
  .text_for_notebook{
    border: 1px solid black !important;
    width: 46% !important;
    height: 32px !important;

  }
  .font_for_notebook{
    border: 1px solid black !important;
    width: 46% !important;
    height: 32px !important;

  }
  .admin-note{
    color:red;
    font-size: 14px;
  }
  .notification-login{
    color: red;
  }
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    /* min-width: 1000px; */
  }
  .invoice_container{
text-align: center;
display: grid;
place-items: center;
}
th{
  font-size: 14px;
  border: 1px solid black;
}
td{
  border: 1px solid #dee2e6;
}
.invoice_goods{
  padding-top: 14px;
  padding-bottom: 300px;
  border-bottom: none !important;
}
.invoice_goods2{
  border-bottom: none !important;
  border-top: none !important;
}

.register-div{
  margin-left: 16px;
}

.generate-button-row{
  padding: 0;
}

.google-login-div{
  height: 44px;
}

.product-div{
  text-align: center;
  width: 350px;
 
  
}

.description{
  font-size: 13px;
}

.title{
  font-size: 15px;
  font-weight: 600;
}

.image img{
  width: 292px;
}

.products{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}


.homepage-header h3{
  text-align: center;
  margin-top: 30px;
}




.inputs{
  
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.reviewImg{ 
  width: 100%;
}

.right-side{
  margin-top: 30px;
}


.product-name{
  font-size: 18px;
  font-weight: 600;
  /* text-align: center; */
  
}

.product-desc{
  font-size: 16px;
  /* text-align: center; */
}

.buttons{
  display: flex;
  justify-content: center;
  gap: 10px;
}

.rating-container{
  text-align: center;
}



.rating-card{
  margin-top: 10% !important;
  margin: auto;
  width: 54%;
  padding: 0px 10px 27px 10px;

  
}
.rating-heading{
  background: rgb(156, 156, 153);
  font-size: 29px;
 
  text-align: center;
}
.rating-desc{
  text-align: center;
  font-size: 15px;
}
.rating-input-login{
  width: 90%;
  margin: auto;
}
.rating-login-button{
  margin-top: 24px;
}
.section2{
  margin-top: 10px;;
  text-align: center;
  text-decoration: underline;
  color: red;
}
.section-size2{
  margin-top: 10px;;
  text-align: center;
  text-decoration: underline;
  color: red;
  font-size: 16px;
}
.size-title{
  font-size: 18px;
  font-weight: bold;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-left: 10px;
  padding-left: 10px;font-size: 15px;

}

table{
    width: 100%;
}

.order{
    margin-top: 50px;
    gap: 65px;
}

.orderId{
    
    font-size: 25px;
    font-weight: 700;

}

.orderDate{
    
    margin-top: 30px;
    font-size: 15px;

}

.logo{
    width: 200px;
}

.logo-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.challan{
    display: flex;  
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 600;
}

.zone{
    display: flex;
    justify-content: right;
    margin-top: auto;
    

}

.zone h2{
    font-size: 25px;
    font-weight: 700;
}

.table-container{
    padding: 200px;
}

.order-rep{
    font-size: 13px;
    font-weight: 400;
}

.order-rep h3{
    font-size: 25px;
    font-weight: 600;
}

.product-design h3{
    font-size: 20px;
}

.product-design img{
    width: 200px;

}

.img-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.invoice_table{
  text-align: left;
}
.tax-invoice-title{
  margin-top: 10px;
}
.heading1{
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px !important;
  /* margin-top: -59px !important; */
}
.heading2{
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px !important;
  /* margin-top: -59px !important; */
}
.design_name-invoice{
  margin-bottom: 200px;
}
.card-inovice{
  width: 70%;
}
.instruction-invoice1{
  margin-top: 9px !important;
  text-align: left;
  margin-bottom: 5px !important;
}
.instruction-invoice2{
  margin-top: 9px !important;
  text-align: right;
  margin-bottom: 5px !important;
}
.sign-invoice{
  width: 196px;
}
